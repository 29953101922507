@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.animation-sequence {
    position: relative;
    width: 100%;
    height: 780px;
    overflow: hidden;
}
  
.background_block {
    background-color: #001f3f; 
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1; 
}
  
.slide_block {
    animation: slideBlock 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    background-color: #003366; 
    height: 100%;
    width: 100%;
    position: absolute;
    left: -100%;
    z-index: 2; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
}

@keyframes slideBlock {
    to {
        left: 0;
    }
}
  
.main_display {
    opacity: 0;
    animation: fadeIn 2s forwards;
    animation-delay: 1.5s; 
    background-color: #EBEBEB; 
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 3; 
    display: flex;
    flex-direction: column;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
  
/* navigation menu styling */
.header {
    width: 100%;
    height: 60px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
}
  
.header h3 {
    margin-left: 10px;
    font-size: 27px;
    color: rgb(70, 69, 69);
}

.header h3:hover{
    color: black;
}
  
.nav_btn {
    display: none;
    opacity: 0;
    background: none;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    font-size: 18px;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
}

.nav_btn:hover{
    cursor: pointer;
}
  
.nav {
    margin-right: 5px;
}
  
.nav a {
    font-family: 'PT Sans', sans-serif;
    font-size: 24px;
    text-decoration: none;
    margin-right: 20px;
    color: rgb(70, 69, 69);
    font-weight: bold;
    position: relative;
    overflow: hidden;
}
  
.nav a::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background-color: red; 
    transition: width 0.3s ease, background-color 0.3s ease;
}

.nav a:hover{
    color: black;
}

.nav a:hover::before {
    width: 100%;
    background-color: blue; 
}

.nav_closed {
    transform: translateX(-100vw);
    transition: transform 0.5s ease;
}

.nav_menu_closed{
    transform: translateX(-100vw);
    transition: transform 0.5s ease;
    background-color: red;
}

.main_container{
    width: 80%;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* background-color: rebeccapurple; */
}

.main_text{
    width: 60%;
    height: 100%;
    /* background-color: orange; */
    order: 1;
}

.main_image{
    width: 40%;
    height: 100%;
    /* background-color: yellow; */
    order: 2;
}

.text_container{
    width: 100%;
    /* background-color: red; */
    margin-top: 200px;

}

.text_container h2{
    font-size: 85px;
}

.text_container p{
    font-size: 23px;
    margin-top: 25px;
}

.text_container h2, p{
    margin-left: 100px;
}


.image_container {
    width: 350px; 
    height: 350px;
    margin-top: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
}

.image_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.socials{
    width: 25%;
    /* background-color: rebeccapurple; */
    margin-top: 25px;
    margin-left: 100px;
}

.socials img{
    width: 35px;
    height: 40px;
    margin-right: 15px;
}

.description_text{
    width: 80%;
}

@media (max-width: 800px) {
    .nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateX(-100vw);
      background-color: #EBEBEB;
      z-index: 1;
    }
  
    .nav_btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      font-weight: bold;
    }
  
    .nav_btn span {
      margin-left: 3px;
      margin-top: 2px;
    }
  
    .responsive_nav {
      transition: 0.5s;
      transform: none;
    }
  
    .nav_btn_close {
      position: absolute;
      top: 12px;
      right: 0px;
    }
  
    .nav a {
      margin-top: 40px;
    }

    .main_container{
        flex-direction: column;
    }

    .main_text, .main_image{
        width: 100%;
    }

    .main_text{
        order: 2;
    }

    .main_image{
        order: 1;
    }

    .image_container{
        margin-top: 40px;
    }

    .text_container{
        margin-top: 10px;
        text-align: center;
    }

    .text_container h2{
        font-size: 68px;
    }

    .image_container{
        width: 300px;
        height: 300px;
    }

    .image_container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

    .description_text{
        width: 100%;
    }

    .text_container h2, p{
        margin-left: 0px;
      }

    .socials{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
  }
  
  
  