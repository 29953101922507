
@import url('https://fonts.googleapis.com/css2?family=Borel&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');


.about_container{
    width: 100%;
    height: 580px;
    display: flex;
}

.about_main{
    width: 70%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    display: flex;
    justify-content: center;

}

.about_text{
    width: 70%;
    height: 100%; 
    opacity: 0;
    margin-top: -200px;
    flex-direction: column; 
    justify-content: center; 
}


.about_text h2{
    font-family: 'Borel',cursive;
    font-size: 63px;
    color: #046a44;
}

.about_text h4{
    font-size: 30px;
    margin-top: 20px;
}

.about_image{
    width: 40%;
    height: 100%;
    opacity: 0;
}



@media (max-width:800px){
    .about_image{
        display: none;
    }

    .about_main{
        width: 100%;
        height: 700px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about_text{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .about_text h2{
        font-size: 45px;
    }

    .about_text h4{
        font-size: 25px;
    }
}