.contact_container{
    width: 100%;
    height: 170px;
    background-color: rgb(234, 234, 234);
    display: flex;
}

.contact_main{
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.contact_main h2{
    font-size: 35px;
    margin-top: 20px;
}

.contact_mail {
    display: flex; 
    align-items: center; 
    margin-top: 30px;
  }
  
.contact_mail img {
    margin-right: 10px; 
    border-radius: 50%; 
    background-color: #f0f0f0; 
    padding: 5px;
    width: 3%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact_mail a{
    text-decoration: none;
    color: black;
}

@media(max-width:800px){
    .contact_container{
        margin-top: 350px;
    }

    .contact_mail img{
        width: 7%;
    }

    .contact_main{
        width: 87%;
    }
  }
  