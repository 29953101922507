.project_main{
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;
}

.project_title{
    text-align: center;
    opacity: 0;
}

.project_container{
    width: 80%;
    height: 600px;
    flex-direction: column;
    margin-top: 180px;
    margin-left: auto;
    margin-right: auto;
}

.project_container a{
  text-decoration: none;
}

.project_container a:visited{
  color: inherit;
}

.project_1_container{
  width: 100%;
  height: 220px;
  display: flex;
  border: 1px solid black;
  padding: 15px;
  border-radius: 30px;
  opacity: 0;
}

.project_1_image{
  width: 40%;
  height: 100%;
}

.project_1_description{
  width: 60%;
  height: 100%;
}

.project_1_description button, .project_2_description button{
  margin-left: 10px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid black;
  padding: 3px;
}

.project_1_description button:hover, .project_2_description button:hover{
  cursor: pointer;
  background-color: black;
  color: white;
}


.project_1_image img{
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.project_1_description h3, .project_2_description h3{
  margin-left: 10px;
}


.project_2_container{
  width: 100%;
  height: 220px;
  display: flex;
  margin-top: 50px;
  border: 1px solid black;
  border-radius: 30px;
  padding: 15px;
  opacity: 0;
}

.project_2_image{
  width: 40%;
  height: 100%;
}

.project_2_description{
  width: 60%;
  height: 100%;
}

.project_2_image img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.highlight{
  background-color: black;
  color: white;
}


@media (max-width:800px){

  .project_main{
    width: 100%;
    display: flex;
    height: 1000px;
    margin-top: 550px;
  }


  .project_1_container, .project_2_container{
    flex-direction: column;
    height: auto;
  }

  .project_1_image, .project_1_description, .project_2_image, .project_2_description{
    width: 100%;
  }

  .project_1_description h3, .project_2_description h3{
    margin-top: 20px;
  }

  .project_container{
    width: 80%;
    height: auto;
    margin-left: 27px;

  }
}






 




 



