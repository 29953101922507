.footer_container{
    width: 100%;
    height: 50px;
    background-color: rgb(168, 168, 168);
    display: flex;
    align-items: center;
}

.footer_container img{
    width: 25%;
    margin: 0 20px;
}

.footer_icons{
    width: 10%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_icons img{
    width: 35px;
}

